var quickSearch = null;

function articleFilterInitialize(event){
	$('.content_filter').show();
	
	var filterSelects = $('.content_filter select');
	$(filterSelects).change(
		function() {
			//cyclone3.log($(this).val());
			articleFilterApply()
		}
	);
	if($(filterSelects).length==1){
		$('.content_filter').addClass('single');
	}
	$(filterSelects).selectBox();
}


function articleFilterRemove(event, lang){
	cyclone3.cancelEvent(event);
	
	var tmpArray = articleFilterValues();
	
	if(tmpArray.length){
		var tmpFilterString='<strong>'+tmpArray.join('</strong>, <strong>') + '</strong>';
		
		$('.maincontent :hidden').remove();
		if (typeof lang=="undefined") {
			$('.content_filter').html('Zobrazuje sa <strong>len</strong> text, zodpovedajúci výberu: ' + tmpFilterString + '.<br />V rovnakom rozsahu sa zobrazí pri tlači. <a href="javascript:document.location.reload();">Obnoviť celý text</a>').addClass('closed');
		}else if (lang=='en') {
			$('.content_filter').html('Showing <strong>only</strong> text, corresponding with: ' + tmpFilterString + '.<br />It will also be printed as filtered. <a href="javascript:document.location.reload();">Reload the complete text</a>').addClass('closed');
		}
		
	}
	
	$(event.target).parent().slideUp(function(){ $(this).remove() });
}


function articleFilterValues() {
	var selectionArray=[];
	try{
		var selects = $('.content_filter').find('select');
		$(selects).each(
			function(index) {
				var tmpfilterVal = $(this).val(); //current filter text
				
				if(tmpfilterVal){
					// cyclone3.log('  no filter value, ignoring this filter')
					selectionArray.push($('select option:selected').text());
				}
			}
		);
	}catch(e){
		cyclone3.log(e);
	}
	return selectionArray;
}


function articleFilterApply() {
	try{
		var filterbox = $('.content_filter');
		var singleSelect = false;
		var selects = $(filterbox).find('select');
		
		singleSelect = (selects.length==1);
		
		//cyclone3.log('singleSelect '+singleSelect)
		
		$(selects).each(function(index) {
			var tmpfilterId = $(this).attr('id'); //current filter id
			var tmpfilterVal = $(this).val(); //current filter value
			var ignoreThisFilter = false;
			
			//cyclone3.log('tmpfilterVal: '+tmpfilterVal);
			
			if(!tmpfilterVal){
				// cyclone3.log('  no filter value, ignoring this filter')
				ignoreThisFilter = true; //ignore this filter, when no value is set
			}
			
			$(this).find('option').each(function(index) {
				var tmpVal = $(this).val();
				var tmpFilterVal = $(this).parent().val();
				
				// cyclone3.log('tmpVal: '+tmpVal);
				
				if(tmpVal){
					if(ignoreThisFilter)
					{
						// we don't care what the option's value is, the filter is disabled
						$('.maincontent .'+tmpVal).each(function(index) {
							// cyclone3.log('showing '+tmpVal);
							$(this).addClass('filtershow');
						});
					}
					else
					{
						// this filter is active, so hide all non-corresponding value classes
						if(tmpFilterVal!=tmpVal)
						{
							cyclone3.log('hiding '+tmpVal);
							// option value doesn't match the select's value, hide this
							$('.maincontent .'+tmpVal).each(function(index) {
								$(this).addClass('filterhide');
							});
						}
						else
						{
							// cyclone3.log('showing '+tmpVal);
							// option value doesn't match the select's value, hide this
							$('.maincontent .'+tmpVal).each(function(index) {
								$(this).addClass('filtershow');
							});
						}
					}
				}
			});
		});
		
		if(singleSelect)
		{
			$('.maincontent .filterhide').each(function(index) {
				if(!$(this).hasClass('filtershow')){
					if($(this).is(':visible')){
						$(this).slideUp();
					}
				}
				$(this).removeClass('filterhide');
			});
			
			$('.maincontent .filtershow').each(function(index) {
				if($(this).not(':visible')){
					$(this).slideDown();
				}
				$(this).removeClass('filtershow');
			});
		}else{
			$('.maincontent .filterhide').each(function(index) {
				if($(this).is(':visible')){
					$(this).slideUp();
				}
				$(this).removeClass('filterhide');
				$(this).removeClass('filtershow');
			});
			
			$('.maincontent .filtershow').each(function(index) {
				if($(this).not(':visible')){
					$(this).slideDown();
				}
				$(this).removeClass('filtershow');
			});
		}
	}catch(e){
		cyclone3.log('error '+e);
	}
}

$(document).ready(function() {
	$('.search').click(function(){
		$(this).css('visibility', 'visible');
	})
	quickSearch = new bsn.QuickSearch(
		'search',
		{
			script:	function (input) {
				return "/xml/search_lite.xml?q="+input;
			},
			noresults: "Nenašli sa žiadne zodpovedajúce výsledky",
			allresults: "Všetky výsledky",
			varname:	"input",
			width: '369'
		}
	);
	if (
		window.location.pathname.match('/poradna')
		&& window.location.hash
	) {
		var tmp = window.location.hash;
		tmp = tmp.replace('#','');
		$('a[name="'+tmp+'"]').parent().trigger('click');
	};

	// newsletter popup 
	function clearErrors() {
		$('.modal').toggleClass('is-visible');
  		setTimeout(function () {      
	  		$('#newsletter_email').removeClass('error');
	  		$('.signup-agree').css({display: 'none'});    
		}, 800);
	};

	$('.modal-toggle').on('click', function(e) {
  		e.preventDefault();
  		$('.modal').toggleClass('is-visible');
	});
	$('.close').on('click', function(e) {
  		e.preventDefault();
  		clearErrors();
	});
	// close popup 
	$('body').on('keyup', function(e) {
    	if(e.which == 27) {
    		clearErrors();
    	}
	});

    //mobile navbar
    $('#mobile-menu').on('click', function() {
        var nav = document.getElementById("navigationItems");
        if (nav.classList.contains('opened')) {
            nav.classList.remove("opened");
            $('#mobile-menu').removeClass("change");
        } else {
            nav.classList.add("opened");
            $('#mobile-menu').addClass("change");
        }
    });

});


