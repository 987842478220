/**
 *  author:		Timothy Groves - http://www.brandspankingnew.net
 *	version:	1.2 - 2006-11-17
 *              1.3 - 2006-12-04
 *              2.0 - 2007-02-07
 *              2.1.1 - 2007-04-13
 *              2.1.2 - 2007-07-07
 *              2.1.3 - 2007-07-19
 *
 */

if (typeof(bsn) == "undefined")
	_b = bsn = {};

if (typeof(_b.QuickSearch) == "undefined")
{ _b.QuickSearch = {}; }
else
{ cyclone3.log("QuickSearch is already set!"); }

_b.QuickSearch = function (id, param)
{
	// no DOM - give up!
	
	if (!document.getElementById)
		return 0;
	
	// get field via DOM
	
	this.fld = _b.DOM.gE(id);

	if (!this.fld)
		return 0;
	
	// init variables
	//alert(this);

	this.sInp 	= "";
	this.nInpC 	= 0;
	this.aSug 	= [];
	this.aSugSec= [];
	this.iHigh 	= 0;

	// parameters object
	
	this.oP = param ? param : {};
	
	// defaults	
	
	var k, def = {
		minchars:		3,
		meth:				"get",
		varname:			"input",
		className:		"quicksearch",
		timeout: 		1000000, //2500
		delay:			100,
		offsety:			-5,
		shownoresults: true,
		noresults: 		"Nenašli sa žiadne zodpovedajúce výsledky",
		maxheight: 		250,
		width: 			'auto',
		//cache: true,
		cache: 			false,
		maxentries: 	25
	};
	for (k in def)
	{
		if (typeof(this.oP[k]) != typeof(def[k]))
			this.oP[k] = def[k];
	}
	
	var p = this;
	
	// set cleaner element for clearing the input value
	
	var pos = _b.DOM.getPos(this.fld);

	this.loader = _b.DOM.cE("span", {id: id+'_loader'});
	this.loader.style.left		= pos.x + this.fld.offsetWidth + "px";
	this.loader.style.top		= pos.y + "px";
	this.loader.style.position	= "absolute";
	this.loader.style.display	= "none";
	
	this.loader = document.getElementsByTagName("body")[0].appendChild(this.loader);
	
	this.cleaner = _b.DOM.cE("span", {id: id+'_cleaner'});
	
	this.cleaner.style.left		= pos.x + this.fld.offsetWidth + "px";
	this.cleaner.style.top		= pos.y + "px";
	this.cleaner.style.position= "absolute";
	this.cleaner.style.display	= "none";
	
	this.cleaner.onclick = function () { p.clearSuggestions(); this.style.display="none"; p.fld.value=''; p.focus(); return false; };
	
	this.cleaner = document.getElementsByTagName("body")[0].appendChild(this.cleaner);
	
	// set keyup handler for field
	// and prevent autocomplete from client
	
	// NOTE: not using addEventListener because UpArrow fired twice in Safari
	//_b.DOM.addEvent( this.fld, 'keyup', function(ev){ return pointer.onKeyPress(ev); } );
	
	this.fld.onkeypress 	= function(ev){ return p.onKeyPress(ev); };
	this.fld.onkeyup 		= function(ev){ return p.onKeyUp(ev); };
	
	this.fld.setAttribute("autocomplete","off");
};

_b.QuickSearch.prototype.onKeyPress = function(ev)
{
	var key = (window.event) ? window.event.keyCode : ev.keyCode;

	// set responses to keydown events in the field
	// this allows the user to use the arrow keys to scroll through the results
	// ESCAPE clears the list
	// TAB sets the current highlighted value
	
	var RETURN = 13;
	var TAB = 9;
	var ESC = 27;
	
	var bubble = 1;

	switch(key)
	{
		case RETURN:
			this.setHighlightedValue();
			bubble = 0;
			break;
		
		case ESC:
			this.clearSuggestions();
			break;
	}

	return bubble;
};

_b.QuickSearch.prototype.onKeyUp = function(ev)
{
	var key = (window.event) ? window.event.keyCode : ev.keyCode;

	// set responses to keydown events in the field
	// this allows the user to use the arrow keys to scroll through the results
	// ESCAPE clears the list
	// TAB sets the current highlighted value

	var ARRUP = 38;
	var ARRDN = 40;
	
	var bubble = 1;

	switch(key)
	{
		case ARRUP:
			this.changeHighlight(key);
			bubble = 0;
			break;
		
		case ARRDN:
			this.changeHighlight(key);
			bubble = 0;
			break;
		
		default:
			this.getSuggestions(this.fld.value);
			if(this.fld.value){
				this.cleaner.style.display = "block";
			}else{
				this.cleaner.style.display = "none";
			}
	}

	return bubble;
};

_b.QuickSearch.prototype.getSuggestions = function (val)
{
	// if input stays the same, do nothing
	//
	if (val == this.sInp)
		return 0;
	
	
	// kill list
	//
	//_b.DOM.remE(this.idAs);
	
	
	this.sInp = val;
	
	
	// input length is less than the min required to trigger a request
	// do nothing
	//
	if (val.length < this.oP.minchars)
	{
		this.aSug = [];
		this.aSugSec = [];
		this.nInpC = val.length;
		return 0;
	}
	
	
	
	
	var ol = this.nInpC; // old length
	this.nInpC = val.length ? val.length : 0;
	
	
	
	// if caching enabled, and user is typing (ie. length of input is increasing)
	// filter results out of aSuggestions from last request
	//
	var l = this.aSug.length;
	if (this.nInpC > ol && l && l<this.oP.maxentries && this.oP.cache)
	{
		var arr = [];
		for (var i=0;i<l;i++)
		{
			if (this.aSug[i].value.substr(0,val.length).toLowerCase() == val.toLowerCase())
				arr.push( this.aSug[i] );
		}
		this.aSug = arr;
		
		this.createList(this.aSug);
		
		
		
		return false;
	}
	else
	// do new request
	//
	{
		var pointer = this;
		var input = this.sInp;
		clearTimeout(this.ajID);
		this.ajID = setTimeout( function() { pointer.doAjaxRequest(input) }, this.oP.delay );
	}

	return false;
};

_b.QuickSearch.prototype.doAjaxRequest = function (input)
{
	// check that saved input is still the value of the field
	//
	if (input != this.fld.value)
		return false;
	
	var pointer = this;
	
	// create ajax request
	//
	if (typeof(this.oP.script) == "function")
		var url = this.oP.script(encodeURIComponent(this.sInp));
	else
		var url = this.oP.script+this.oP.varname+"="+encodeURIComponent(this.sInp);
	
	if (!url)
		return false;
	
	var meth = this.oP.meth;
	var input = this.sInp;
	
	var onSuccessFunc = function (req) { pointer.setSuggestions(req, input) };
	var onErrorFunc = function (status) {
		cyclone3.log("AJAX error: "+status);
	};

	var myAjax = new _b.Ajax();
	myAjax.makeRequest( url, meth, onSuccessFunc, onErrorFunc );
	
	this.loader.style.display="block";
};

_b.QuickSearch.prototype.setSuggestions = function (req, input)
{
	this.loader.style.display="none";
	
	// if field input no longer matches what was passed to the request
	// don't show the suggestions
	//
	if (input != this.fld.value)
		return false;
	
	
	this.aSug = [];
	this.aSugSec = [];
	
	
	if (this.oP.json)
	{
		var jsondata = eval('(' + req.responseText + ')');
		
		for (var i=0;i<jsondata.results.length;i++)
		{
			this.aSug.push(  { 'id':jsondata.results[i].id, 'value':jsondata.results[i].value, 'info':jsondata.results[i].info }  );
		}
	}
	else
	{
		var xml = req.responseXML;
		
		var categories = xml.getElementsByTagName('results')[0].childNodes;
		
		for (var i=0;i<categories.length;i++)
		{
			if (categories[i].hasChildNodes())
			{
				this.aSugSec.push(
					categories[i]
				);
			}
		}
		
		/* original, traversing only items, not allowing sections */
		// traverse xml
		//var results = xml.getElementsByTagName('results')[0].childNodes;
		//
		//for (var i=0;i<results.length;i++)
		//{
		//	if (results[i].hasChildNodes())
		//	{
		//		this.aSug.push(
		//			{
		//				'id':results[i].getAttribute('id'),
		//				'value':results[i].childNodes[0].nodeValue,
		//				'info':results[i].getAttribute('info')
		//			}
		//		);
		//	}
		//}
	}
	
	this.idAs = "as_"+this.fld.id;
	
	this.createList(this.aSugSec);
	//this.createList(this.aSug);

};

_b.QuickSearch.prototype.createList = function(arr)
{
	try
	{
		var pointer = this;
		
		// get rid of old list
		// and clear the list removal timeout
		
		_b.DOM.remE(this.idAs);
		this.killTimeout();
		
		// if no results, and shownoresults is false, do nothing
		
		if (arr.length == 0 && !this.oP.shownoresults)
			return false;
		
		// create holding div
		
		var div = _b.DOM.cE("div", {id:this.idAs, className:this.oP.className});	
		
		var hcorner = _b.DOM.cE("div", {className:"as_corner"});
		var hbar = _b.DOM.cE("div", {className:"as_bar"});
		var header = _b.DOM.cE("div", {className:"as_header"});
		header.appendChild(hcorner);
		header.appendChild(hbar);
		div.appendChild(header);
		
		// create and populate ul
		
		var ul = _b.DOM.cE("ul", {id:"as_ul"});
		
		var highlighterCounter=1;
		
		for (var sect=0;sect<arr.length;sect++)
		{
			var categoryType = arr[sect].getAttribute('type');
			var categoryLabel = arr[sect].getAttribute('label');
			cyclone3.log('[quicksearch] found section '+categoryLabel)
			
			var sectionitems = arr[sect].getElementsByTagName('item');
			
			if(categoryType!='faq' && categoryType!='newsletter' && categoryType!='autosuggest'){
				continue;
			}
			
			if(sectionitems.length)
			{
				cyclone3.log('[quicksearch]   count: '+sectionitems.length);
				
				highlighterCounter--; //lower the counter, we won't be displaying "autosuggest" category heading
				
				//cyclone3.log('[quicksearch] highlighterCounter (section): '+highlighterCounter);
				
				cyclone3.log('[quicksearch] sectionitems:');
				cyclone3.log(arr);
				
				for (var i=0; i<sectionitems.length; i++)
				{
					cyclone3.log('[quicksearch]   item: ' + i + ' ' + sectionitems[i].tagName)
					
					if(sectionitems[i].tagName == 'item')
					{
						cyclone3.log(sectionitems[i]);
						
						
						// format output with the input enclosed in a EM element
						// (as HTML, not DOM)
						
						//var val = sectionitems[i].getElementsByTagName('title')[0].childNodes[0].nodeValue;
						//var st = val.toLowerCase().indexOf( this.sInp.toLowerCase() );
						//var output = val.substring(0,st) + "<em>" + val.substring(st, st+this.sInp.length) + "</em>" + val.substring(st+this.sInp.length);
						
						var itemType, itemTitle, itemDescription, itemLink, itemLabel, itemThumb;
						
						var itemTitle = sectionitems[i].getElementsByTagName('title')[0].childNodes[0].nodeValue;
						
						//itemLabel = sectionitems[i].getAttribute('label');
						//itemType = sectionitems[i].getAttribute('type');
						//itemTitle = sectionitems[i].nodeValue;
						//itemTitle = sectionitems[i].nodeValue;
						//itemTitle = sectionitems[i].getElementsByTagName('caption')[0].childNodes[0].nodeValue;
						//itemThumb = sectionitems[i].getElementsByTagName('thumbnail')[0].childNodes[0].nodeValue;
						
						//itemLink = sectionitems[i].getAttribute('uri');
						itemLink = sectionitems[i].getElementsByTagName('link')[0].childNodes[0].nodeValue;
						
						switch (categoryType)
						{
							case 'products':
								itemDescription = sectionitems[i].getElementsByTagName('description')[0].childNodes[0].nodeValue;
								break;
							default:
								break;
						}
						
						cyclone3.log('[quicksearch]  itemTitle: '+itemTitle) 
						cyclone3.log('[quicksearch]  itemLink: '+itemLink)
						
						var elItem 			= _b.DOM.cE("span", {});
						
						//var elItemThumb = _b.DOM.cE("img",{src:itemThumb,width:"30"});
						//var elItemThumbWrap = _b.DOM.cE("span",{className:'image'});
						//var elItemThumb = _b.DOM.cE("img",{src:itemThumb});
						var elItemTitle	= _b.DOM.cE("strong", {}, itemTitle + '<br />', true);
						//elItem.appendChild(elItemThumb);
						elItem.appendChild(elItemTitle);
						
						if(itemDescription){
							var elItemDescription = _b.DOM.cE("small", {}, itemDescription, true);
							elItem.appendChild(elItemDescription);
						}
						//if (sectionitems[i].info != "")
						//{
						//	var br			= _b.DOM.cE("br", {});
						//	span.appendChild(br);
						//	var small		= _b.DOM.cE("small", {}, sectionitems[i].info);
						//	span.appendChild(small);
						//}
						
						var a 			= _b.DOM.cE("a", { href: itemLink, title: itemTitle });
						
						//var tl 		= _b.DOM.cE("span", {className:"tl"}, " ");
						//var tr 		= _b.DOM.cE("span", {className:"tr"}, " ");
						//a.appendChild(tl);
						//a.appendChild(tr);
						
						//elItemThumbWrap.appendChild(elItemThumb);
						//a.appendChild(elItemThumbWrap);
						a.appendChild(elItem);
						
						highlighterCounter++;
						cyclone3.log('[quicksearch] highlighterCounter (item): '+highlighterCounter);
						
						a.name = highlighterCounter;
						a.onclick = function () { pointer.setHighlightedValue(); return false; };
						a.onmouseover = function () { pointer.setHighlight(this.name); };
						
						var li = _b.DOM.cE(  "li", { className: 'item '+sectionitems[i].getAttribute('type')}, a  );
						
						ul.appendChild( li );
					}
				}
				
				highlighterCounter++;
			}
		}
		
		if (arr.length == 0 && this.oP.shownoresults)
		{
			var li = _b.DOM.cE(  "li", {className:"as_warning"}, this.oP.noresults  );
			ul.appendChild( li );
		}
		
		highlighterCounter++;
		
//		var span = _b.DOM.cE("span", {}, '<strong>Nenašli ste, čo ste potrebovali?</strong> Napíšte nám', true);
//		var a 			= _b.DOM.cE("a", { href:"/odoslat-otazku" });
//		a.name = highlighterCounter;
//		a.onclick = function () { pointer.setHighlightedValue(); return false; };
//		a.onmouseover = function () { pointer.setHighlight(this.name); };
//		a.appendChild(span);
//		var li = _b.DOM.cE(  "li", {className: "notfound"}, a  );
//		ul.appendChild( li );
		
		/* original, parsing only items, no categories allowed */
		
		// loop throught arr of suggestions
		// creating an LI element for each suggestion
		
		//for (var i=0;i<arr.length;i++)
		//{
		//	// format output with the input enclosed in a EM element
		//	// (as HTML, not DOM)
		//	//
		//	var val = arr[i].value;
		//	var st = val.toLowerCase().indexOf( this.sInp.toLowerCase() );
		//	var output = val.substring(0,st) + "<em>" + val.substring(st, st+this.sInp.length) + "</em>" + val.substring(st+this.sInp.length);
		//	
		//	
		//	var span 		= _b.DOM.cE("span", {}, output, true);
		//	if (arr[i].info != "")
		//	{
		//		var br			= _b.DOM.cE("br", {});
		//		span.appendChild(br);
		//		var small		= _b.DOM.cE("small", {}, arr[i].info);
		//		span.appendChild(small);
		//	}
		//	
		//	var a 			= _b.DOM.cE("a", { href:"#" });
		//	
		//	var tl 		= _b.DOM.cE("span", {className:"tl"}, " ");
		//	var tr 		= _b.DOM.cE("span", {className:"tr"}, " ");
		//	a.appendChild(tl);
		//	a.appendChild(tr);
		//	
		//	a.appendChild(span);
		//	
		//	a.name = i+1;
		//	a.onclick = function () { pointer.setHighlightedValue(); return false; };
		//	a.onmouseover = function () { pointer.setHighlight(this.name); };
		//	
		//	var li = _b.DOM.cE(  "li", {}, a  );
		//	
		//	ul.appendChild( li );
		//}
		
		// no results
		//
		//if (arr.length == 0 && this.oP.shownoresults)
		//{
		//	var li = _b.DOM.cE(  "li", {className:"as_warning"}, this.oP.noresults  );
		//	ul.appendChild( li );
		//}
		
		div.appendChild( ul );
		
		var fcorner = _b.DOM.cE("div", {className:"as_corner"});
		var fbar = _b.DOM.cE("div", {className:"as_bar"});
		var footer = _b.DOM.cE("div", {className:"as_footer"});
		footer.appendChild(fcorner);
		footer.appendChild(fbar);
		div.appendChild(footer);
		
		// get position of target textfield
		// position holding div below it
		// set width of holding div to width of field
		//
		var pos = _b.DOM.getPos(this.fld);
		
		div.style.left 		= pos.x + "px";
		div.style.top 		= ( pos.y + this.fld.offsetHeight + this.oP.offsety ) + "px";
		
		if(this.oP.width!='auto'){
			div.style.width = this.oP.width + "px";
		}
		else
		{
			div.style.width = this.fld.offsetWidth + "px";
		}
		
		// set mouseover functions for div
		// when mouse pointer leaves div, set a timeout to remove the list after an interval
		// when mouse enters div, kill the timeout so the list won't be removed
		//
		div.onmouseover 	= function(){ pointer.killTimeout() };
		div.onmouseout 		= function(){ pointer.resetTimeout() };
		
		// add DIV to document
		//
		document.getElementsByTagName("body")[0].appendChild(div);
		
		// currently no item is highlighted
		//
		this.iHigh = 0;
		
		// remove list after an interval
		//
		var pointer = this;
		this.toID = setTimeout(function () { pointer.clearSuggestions() }, this.oP.timeout);
	}catch(e){
		cyclone3.log(e);
	}
};

_b.QuickSearch.prototype.changeHighlight = function(key)
{	
	var list = _b.DOM.gE("as_ul");
	if (!list)
		return false;
	
	var n;

	if (key == 40)
		n = this.iHigh + 1;
	else if (key == 38)
		n = this.iHigh - 1;
	
	
	if (n > list.childNodes.length)
		n = list.childNodes.length;
	if (n < 1)
		n = 1;
	
	
	this.setHighlight(n);
};

_b.QuickSearch.prototype.setHighlight = function(n)
{
	var list = _b.DOM.gE("as_ul");
	if (!list)
		return false;
	
	if (this.iHigh > 0)
		this.clearHighlight();
	
	this.iHigh = Number(n);
	
	list.childNodes[this.iHigh-1].className = list.childNodes[this.iHigh-1].className + " as_highlight";

	this.killTimeout();
};

_b.QuickSearch.prototype.clearHighlight = function()
{
	var list = _b.DOM.gE("as_ul");
	if (!list)
		return false;
	
	if (this.iHigh > 0)
	{
		list.childNodes[this.iHigh-1].className = list.childNodes[this.iHigh-1].className.replace(' as_highlight', '');;
		this.iHigh = 0;
	}
};

_b.QuickSearch.prototype.setHighlightedValue = function ()
{
	if (this.iHigh)
	{
		this.sInp = this.fld.value = this.aSug[ this.iHigh-1 ].value;
		
		// move cursor to end of input (safari)
		//
		this.fld.focus();
		if (this.fld.selectionStart)
			this.fld.setSelectionRange(this.sInp.length, this.sInp.length);
		

		this.clearSuggestions();
		
		// pass selected object to callback function, if exists
		//
		if (typeof(this.oP.callback) == "function")
			this.oP.callback( this.aSug[this.iHigh-1] );
	}
};

_b.QuickSearch.prototype.killTimeout = function()
{
	clearTimeout(this.toID);
};

_b.QuickSearch.prototype.resetTimeout = function()
{
	clearTimeout(this.toID);
	var pointer = this;
	this.toID = setTimeout(function () { pointer.clearSuggestions() }, 1000);
};

_b.QuickSearch.prototype.clearSuggestions = function ()
{
	
	this.killTimeout();
	
	var ele = _b.DOM.gE(this.idAs);
	var pointer = this;
	if (ele)
	{
		//var fade = new _b.Fader(ele,1,0,250,function () { _b.DOM.remE(pointer.idAs) });
	}
};

if (typeof(_b.Ajax) == "undefined")
	_b.Ajax = {};

_b.Ajax = function ()
{
	this.req = {};
	this.isIE = false;
};

_b.Ajax.prototype.makeRequest = function (url, meth, onComp, onErr)
{
	
	if (meth != "POST")
		meth = "GET";
	
	this.onComplete = onComp;
	this.onError = onErr;
	
	var pointer = this;
	
	// branch for native XMLHttpRequest object
	if (window.XMLHttpRequest)
	{
		this.req = new XMLHttpRequest();
		this.req.onreadystatechange = function () { pointer.processReqChange() };
		this.req.open("GET", url, true); //
		this.req.send(null);
	// branch for IE/Windows ActiveX version
	}
	else if (window.ActiveXObject)
	{
		this.req = new ActiveXObject("Microsoft.XMLHTTP");
		if (this.req)
		{
			this.req.onreadystatechange = function () { pointer.processReqChange() };
			this.req.open(meth, url, true);
			this.req.send();
		}
	}
};

_b.Ajax.prototype.processReqChange = function()
{
	// only if req shows "loaded"
	if (this.req.readyState == 4) {
		// only if "OK"
		if (this.req.status == 200)
		{
			this.onComplete( this.req );
		} else {
			this.onError( this.req.status );
		}
	}
};

if (typeof(_b.DOM) == "undefined")
	_b.DOM = {};

_b.DOM.cE = function ( type, attr, cont, html )
{
	var ne = document.createElement( type );
	if (!ne)
		return 0;
		
	for (var a in attr)
		ne[a] = attr[a];
	
	var t = typeof(cont);
	
	if (t == "string" && !html)
		ne.appendChild( document.createTextNode(cont) );
	else if (t == "string" && html)
		ne.innerHTML = cont;
	else if (t == "object")
		ne.appendChild( cont );

	return ne;
};

_b.DOM.gE = function ( e )
{
	var t=typeof(e);
	if (t == "undefined")
		return 0;
	else if (t == "string")
	{
		var re = document.getElementById( e );
		if (!re)
			return 0;
		else if (typeof(re.appendChild) != "undefined" )
			return re;
		else
			return 0;
	}
	else if (typeof(e.appendChild) != "undefined")
		return e;
	else
		return 0;
};

_b.DOM.remE = function ( ele )
{
	var e = this.gE(ele);
	
	if (!e)
		return 0;
	else if (e.parentNode.removeChild(e))
		return true;
	else
		return 0;
};

_b.DOM.getPos = function ( e )
{
	var e = this.gE(e);

	var obj = e;

	var curleft = 0;
	if (obj.offsetParent)
	{
		while (obj.offsetParent)
		{
			curleft += obj.offsetLeft;
			obj = obj.offsetParent;
		}
	}
	else if (obj.x)
		curleft += obj.x;
	
	var obj = e;
	
	var curtop = 0;
	if (obj.offsetParent)
	{
		while (obj.offsetParent)
		{
			curtop += obj.offsetTop;
			obj = obj.offsetParent;
		}
	}
	else if (obj.y)
		curtop += obj.y;

	return {x:curleft, y:curtop};
};

if (typeof(_b.Fader) == "undefined")
	_b.Fader = {};

_b.Fader = function (ele, from, to, fadetime, callback)
{	
	if (!ele)
		return 0;
	
	this.e = ele;
	
	this.from = from;
	this.to = to;
	
	this.cb = callback;
	
	this.nDur = fadetime;
		
	this.nInt = 50;
	this.nTime = 0;
	
	var p = this;
	this.nID = setInterval(function() { p._fade() }, this.nInt);
};

_b.Fader.prototype._fade = function()
{
	this.nTime += this.nInt;
	
	var ieop = Math.round( this._tween(this.nTime, this.from, this.to, this.nDur) * 100 );
	var op = ieop / 100;
	
	if (this.e.filters) // internet explorer
	{
		try
		{
			this.e.filters.item("DXImageTransform.Microsoft.Alpha").opacity = ieop;
		} catch (e) { 
			// If it is not set initially, the browser will throw an error.  This will set it if it is not set yet.
			this.e.style.filter = 'progid:DXImageTransform.Microsoft.Alpha(opacity='+ieop+')';
		}
	}
	else // other browsers
	{
		this.e.style.opacity = op;
	}
	
	if (this.nTime == this.nDur)
	{
		clearInterval( this.nID );
		if (this.cb != undefined)
			this.cb();
	}
};

_b.Fader.prototype._tween = function(t,b,c,d)
{
	return b + ( (c-b) * (t/d) );
};